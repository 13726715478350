import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'
import { t } from 'Root/app/IntlProvider'
import storage from 'Utils/storage'

const appReducer = createReducer(initialState.app, {
  [types.APP_DOCK_NAVBAR]: (state, action) => ({
    ...state,
    navbarDocked: action.navbarDocked,
  }),
  [types.APP_FOLD_PANELS]: (state, action) => {
    const { entity } = action
    const panels = { ...state.panels }
    for (const panel in panels) {
      if (panel.startsWith(entity)) {
        panels[panel] = false
      }
    }
    return { ...state, panels }
  },
  [types.APP_OPEN_NAVBAR]: (state, action) => ({
    ...state,
    navbarOpen: action.navbarOpen,
  }),
  [types.APP_REGISTER_PANEL]: (state, action) => {
    const { expanded, id } = action
    const panels = { ...state.panels }
    if (!Object.prototype.hasOwnProperty.call(panels, id)) {
      panels[id] = expanded
    }
    return { ...state, panels }
  },
  [types.APP_SET_LANGUAGE]: (state, { language }) => ({ ...state, language }),
  [types.APP_SET_ONLINE]: (state, action) => ({
    ...state,
    online: action.online,
  }),
  [types.APP_SET_DELIMITERS]: (state, { decimal, thousands }) => ({
    ...state,
    decimalSeparator: decimal,
    thousandSeparator: thousands,
  }),
  [types.APP_SET_PANEL_EXPANDED]: (state, action) => {
    const panels = { ...state.panels, [action.id]: action.value }
    // save local storage
    storage.set('panels', JSON.stringify(panels))
    return { ...state, panels }
  },
  [types.APP_SET_VERSION]: (state, { version }) => {
    return { ...state, version }
  },
  [types.APP_SET_HAS_RELEASE_NOTES]: (state, { value }) => {
    return { ...state, hasReleaseNotes: value }
  },
  [types.APP_SHOW_NEW_VERSION]: (state, { value }) => {
    return { ...state, newVersion: value }
  },
  [types.APP_UNFOLD_PANELS]: (state, action) => {
    const { entity } = action
    const panels = { ...state.panels }
    for (const panel in panels) {
      if (panel.startsWith(entity)) {
        panels[panel] = true
      }
    }
    return { ...state, panels }
  },
  [types.LOOKUP_SET_COUNTRIES]: (state, { data }) => {
    const props = data
    for (const prop in props) {
      const property = props[prop]
      if (!property.name) {
        property.name = t(`lookup.country.${prop}`)
      }
    }

    return {
      ...state,
      countries: props,
    }
  },
  [types.LOOKUP_SET_LANGUAGES]: (state, { data }) => {
    const props = data
    for (const prop in props) {
      const property = props[prop]
      if (!property.name) {
        property.name = t(`lookup.language.${prop}`)
      }
    }

    return {
      ...state,
      languages: props,
    }
  },
})

export default appReducer
