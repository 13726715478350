import history from 'Utils/history'
import numeral from 'numeral'
import { call, delay, put, select, take } from 'redux-saga/effects'
import {
  getFormSyncErrors,
  initialize,
  isDirty,
  isValid,
  submit,
  touch,
} from 'redux-form'
import { http } from 'Utils/http'
import {
  setDelimiters,
  setHasReleaseNotes,
  setLanguage,
  setVersion,
  showNewVersion,
} from 'Actions/app'
import { confirmSaga, messageSaga } from './shell'
import { t } from 'Root/app/IntlProvider'
import { keyify } from 'Utils/object'
import {
  setPrevLocation as setPrevLocationEntity,
  setSelection,
} from 'Actions/entity'
import { setPrevLocation as setPrevLocationTable } from 'Actions/table'
import { getItem } from 'Actions/event'
import storage from 'Utils/storage'

export function* changeLanguageSaga(action) {
  const { language } = action
  storage.set('language', language)
  window.recaptchaOptions = {
    useRecaptchaNet: true,
  }
  yield put(setLanguage(language))
  const delimiters = numeral.locales[language].delimiters
  yield put(setDelimiters(delimiters.decimal, delimiters.thousands))
}

export function* gotoUrlSaga(action) {
  const state = yield select()
  const dirty = isDirty(state.entity.info.entityType)(state)
  if (dirty) {
    const ok = yield call(confirmSaga, {
      title: t('entity.message.saveChanges.title'),
      content: t('entity.message.saveChanges.content'),
    })
    if (ok) {
      const valid = isValid(state.entity.info.entityType)(state)
      const errors = getFormSyncErrors(state.entity.info.entityType)(state)
      if (valid) {
        yield put(submit(state.entity.info.entityType))
        yield take('@@redux-form/SET_SUBMIT_SUCCEEDED')
      } else {
        yield delay(500)
        yield call(messageSaga, {
          title: t('entity.message.invalidChanges.title'),
          content: t('entity.message.invalidChanges.content'),
        })
        yield put(touch(state.entity.info.entityType, ...keyify(errors)))
        return
      }
    }
    yield put(initialize(state.entity.info.entityType))
  }
  const dirtyLang = isDirty('editLanguage')(state)
  if (dirtyLang) {
    const ok = yield call(confirmSaga, {
      title: t('job.message.saveChanges.title'),
      content: t('job.message.saveChanges.content'),
    })
    if (ok) {
      yield put(submit('editLanguage'))
      yield take('@@redux-form/SET_SUBMIT_SUCCEEDED')
    }
    yield put(initialize('editLanguage'))
  }
  const dirtyCompany = isDirty('companyGeneral')(state)
  if (dirtyCompany) {
    const ok = yield call(confirmSaga, {
      title: t('company.message.saveChanges.title'),
      content: t('company.message.saveChanges.content'),
    })
    if (ok) {
      const valid = isValid('companyGeneral')(state)
      const errors = getFormSyncErrors('companyGeneral')(state)
      if (valid) {
        yield put(submit('companyGeneral'))
        yield take('@@redux-form/SET_SUBMIT_SUCCEEDED')
      } else {
        yield delay(500)
        yield call(messageSaga, {
          title: t('company.message.invalidChanges.title'),
          content: t('company.message.invalidChanges.content'),
        })
        yield put(touch('companyGeneral', ...keyify(errors)))
        return
      }
    }
    yield put(initialize('companyGeneral'))
  }
  const dirtyCompanySettings = isDirty('companySettings')(state)
  if (dirtyCompanySettings) {
    const ok = yield call(confirmSaga, {
      title: t('company.message.saveChanges.title'),
      content: t('company.message.saveChanges.content'),
    })
    if (ok) {
      yield put(submit('companySettings'))
      yield take('@@redux-form/SET_SUBMIT_SUCCEEDED')
    }
    yield put(initialize('companySettings'))
  }
  const dirtyCompanyConnect = isDirty('companyConnect')(state)
  if (dirtyCompanyConnect) {
    const ok = yield call(confirmSaga, {
      title: t('company.message.saveChanges.title'),
      content: t('company.message.saveChanges.content'),
    })
    if (ok) {
      const valid = isValid('companyConnect')(state)
      const errors = getFormSyncErrors('companyConnect')(state)
      if (valid) {
        yield put(submit('companyConnect'))
        yield take('@@redux-form/SET_SUBMIT_SUCCEEDED')
      } else {
        yield delay(500)
        yield call(messageSaga, {
          title: t('company.message.invalidChanges.title'),
          content: t('company.message.invalidChanges.content'),
        })
        yield put(touch('companyConnect', ...keyify(errors)))
        return
      }
    }
    yield put(initialize('companyConnect'))
  }
  const dirtyProfile = isDirty('profileGeneral')(state)
  if (dirtyProfile) {
    const ok = yield call(confirmSaga, {
      title: t('user.message.saveChanges.title'),
      content: t('user.message.saveChanges.content'),
    })
    if (ok) {
      const valid = isValid('profileGeneral')(state)
      const errors = getFormSyncErrors('profileGeneral')(state)
      if (valid) {
        yield put(submit('profileGeneral'))
        yield take('@@redux-form/SET_SUBMIT_SUCCEEDED')
      } else {
        yield delay(500)
        yield call(messageSaga, {
          title: t('user.message.invalidChanges.title'),
          content: t('user.message.invalidChanges.content'),
        })
        yield put(touch('profileGeneral', ...keyify(errors)))
        return
      }
    }
    yield put(initialize('profileGeneral'))
  }
  const dirtyProfileSettings = isDirty('profileSettings')(state)
  if (dirtyProfileSettings) {
    const ok = yield call(confirmSaga, {
      title: t('user.message.saveChanges.title'),
      content: t('user.message.saveChanges.content'),
    })
    if (ok) {
      yield put(submit('profileSettings'))
      yield take('@@redux-form/SET_SUBMIT_SUCCEEDED')
    }
    yield put(initialize('profileSettings'))
  }
  const {
    resolve,
    url,
    prevLocationEntity,
    prevLocationTable,
    selection = [],
    newTab = false,
  } = action.data
  if (prevLocationEntity) {
    yield put(setPrevLocationEntity(prevLocationEntity))
  }
  yield put(setSelection(selection))
  const parts = (url || '').split('/').filter((x) => x)
  if (parts.length === 2 && parts[0] === 'events' && parts[1] !== '') {
    yield put(getItem({ id: parts[1] }))
  } else {
    if (newTab) {
      const win = window.open(url)
      win.focus()
    } else {
      yield call(history.push, url)
    }
  }
  if (resolve) {
    yield call(resolve)
  }
  if (prevLocationTable) {
    yield delay(500)
    yield put(setPrevLocationTable(prevLocationTable))
  }
}

export function* sendErrorSaga(action) {
  const { error, stack, url } = action
  const currentState = yield select()
  const state = JSON.stringify(currentState)
  yield http.post(`/errors`, { error, stack, url, state })
}

export function* startVersionCheckSaga() {
  while (true) {
    try {
      const response = yield fetch('/version')
      const result = yield response.text()
      const values = result.split(result, '-')
      const newVersion = values[0]
      const hasReleaseNotes = values.length > 1 ? !!values[1] : false
      const state = yield select()
      const oldVersion = state.app.version
      yield put(setHasReleaseNotes(hasReleaseNotes))
      if (oldVersion === null) {
        yield put(setVersion(newVersion))
      } else if (oldVersion !== newVersion) {
        yield put(showNewVersion(true))
      }
    } catch (e) {}
    // check every 30 minutes
    yield delay(1000 * 60 * 30)
  }
}
