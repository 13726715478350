import * as types from 'Actions/types'
import { createAction } from 'Utils/redux'

export const addChannel = createAction(
  types.CAMPAIGN_ADD_CHANNEL,
  'id',
  'tab',
  'channelId'
)
export const getChannels = createAction(
  types.CAMPAIGN_GET_CHANNELS,
  'id',
  'options'
)
export const getChannelsSucceed = createAction(
  types.CAMPAIGN_GET_CHANNELS_SUCCEED,
  'data'
)
export const getJobInfo = createAction(types.CAMPAIGN_GET_JOB_INFO, 'payload')
export const loadLists = createAction(types.CAMPAIGN_LOAD_LISTS)
export const loadListsSucceed = createAction(
  types.CAMPAIGN_LOAD_LISTS_SUCCEED,
  'data'
)
export const refreshChannels = createAction(
  types.CAMPAIGN_REFRESH_CHANNELS,
  'id',
  'tab'
)
export const removeChannel = createAction(
  types.CAMPAIGN_REMOVE_CHANNEL,
  'id',
  'tab',
  'channelId'
)
export const setFavorite = createAction(types.CAMPAIGN_SET_FAVORITE, 'id')
export const setFilter = createAction(types.CAMPAIGN_SET_FILTER, 'filter')
export const setLoading = createAction(types.CAMPAIGN_SET_LOADING, 'value')
export const setOrder = createAction(types.CAMPAIGN_SET_ORDER, 'order')
export const setSearch = createAction(types.CAMPAIGN_SET_SEARCH, 'search')
export const setSkip = createAction(types.CAMPAIGN_SET_SKIP, 'skip')
export const setTab = createAction(types.CAMPAIGN_SET_TAB, 'tab')
export const showFilter = createAction(types.CAMPAIGN_SHOW_FILTER, 'value')
export const toggleCart = createAction(types.CAMPAIGN_TOGGLE_CART)
export const unsetFavorite = createAction(types.CAMPAIGN_UNSET_FAVORITE, 'id')
