import * as types from 'Actions/types'
import { createReducer } from 'Utils/redux'
import initialState from './initialState'

const searchReducer = createReducer(initialState.search, {
  [types.SEARCH_ADD_TO_LIST]: (state, { item }) => ({
    ...state,
    list: [item, ...state.list],
  }),
  [types.SEARCH_ADD_TO_SELECTED]: (state, { id }) => ({
    ...state,
    selected: [...state.selected, id],
  }),
  [types.SEARCH_SEARCH_TEXT_SUCCEED]: (state, action) => ({
    ...state,
    text: action.text || '',
    totals: action.totals || {},
    list: action.data.map(
      ({ city, customerName, distance, email, id, name, type, image }) => ({
        city,
        distance,
        email: email || customerName,
        id,
        name,
        type,
        image,
      })
    ),
  }),
  [types.SEARCH_SET_LIST]: (state, { items }) => ({
    ...state,
    list: items.map(({ id, description, email, icon, color, order }) => ({
      id,
      name: description,
      email,
      icon,
      color,
      order,
    })),
  }),
  [types.SEARCH_SET_NO_RESULT]: (state, action) => ({
    ...state,
    noResult: action.value,
  }),
  [types.SEARCH_SET_SELECTED]: (state, { selected }) => ({
    ...state,
    selected,
  }),
  [types.AUTH_SIGNOUT_SUCCEED]: () => initialState.search,
})

export default searchReducer
