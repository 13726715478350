import { call, delay, put, select } from 'redux-saga/effects'
import { http } from 'Utils/http'
import { globalError } from './shell'
import {
  getChannels,
  getChannelsSucceed,
  loadListsSucceed,
  refreshChannels,
  setLoading,
} from 'Actions/campaign'
import { updateData } from 'Actions/entity'
import { setLoading as setLoadingEntity } from 'Actions/entity'
import { updateField } from 'Actions/form'

export function* addChannelSaga(action) {
  const { channelId, id, tab } = action
  yield put(setLoading(true))
  const { ok, error, data } = yield http.post(
    `/campaigns/${id}/channels/${channelId}`
  )
  if (ok) {
    yield put(updateData({ channels: data }))
    yield put(refreshChannels(id, tab))
  } else if (error) {
    yield call(globalError, data)
    yield put(setLoading(false))
  }
}

export function* getJobInfoSaga(action) {
  const {
    payload: { id, resolve, reject },
  } = action
  const { ok, error, data } = yield http.get(`/campaigns/job/${id}`)
  if (ok) {
    yield put(
      updateField(
        { form: 'addCampaign', field: 'name', touch: true },
        data.name
      )
    )
    if (data.educations) {
      yield put(
        updateField(
          { form: 'addCampaign', field: 'educations', touch: true },
          data.educations
        )
      )
    }
    if (data.jobLevel) {
      yield put(
        updateField(
          { form: 'addCampaign', field: 'jobLevel', touch: true },
          data.jobLevel
        )
      )
    }
    if (data.jobProfiles) {
      yield put(
        updateField(
          { form: 'addCampaign', field: 'jobProfiles', touch: true },
          data.jobProfiles
        )
      )
    }
    if (data.regions) {
      yield put(
        updateField(
          { form: 'addCampaign', field: 'regions', touch: true },
          data.regions
        )
      )
    }
    if (data.sector) {
      yield put(
        updateField(
          { form: 'addCampaign', field: 'sector', touch: true },
          data.sector
        )
      )
    }
    yield call(resolve, data)
  } else if (error) {
    yield call(reject)
    yield call(globalError, data)
  }
}

export function* getListsSaga() {
  const { ok, error, data } = yield http.get('/campaigns/lists')
  if (ok) {
    yield put(loadListsSucceed(data))
  } else if (error) {
    yield call(globalError, data)
  }
}

export function* getChannelsSaga(action) {
  let { id, options = {} } = action
  try {
    yield put(setLoading(true))
    yield put(setLoadingEntity(true))
    yield delay(100)
    const state = yield select()
    if (!id) {
      id = state.entity.info.id
      const { order, search, skip, tab } = state.campaign.item
      options = {
        order,
        search,
        skip,
        tab,
      }
    }
    options.educations = state.campaign.item.filter.educations
    options.regions = state.campaign.item.filter.regions
    options.channelTypes = state.campaign.item.filter.channelTypes
    options.sector = state.campaign.item.filter.sector
    const { ok, error, data } = yield http.post(
      `/campaigns/${id}/channels`,
      options
    )
    if (ok) {
      yield put(getChannelsSucceed(data))
    } else if (error) {
      yield call(globalError, data)
    }
  } finally {
    yield put(setLoading(false))
    yield put(setLoadingEntity(false))
  }
}

export function* refreshChannelsSaga(action) {
  const { id, tab } = action
  const state = yield select()
  const { order, search, skip } = state.campaign.item
  yield put(getChannels(id, { order, search, skip, tab }))
}

export function* removeChannelSaga(action) {
  const { channelId, id, tab } = action
  yield put(setLoading(true))
  const { ok, error, data } = yield http.delete(
    `/campaigns/${id}/channels/${channelId}`
  )
  if (ok) {
    yield put(updateData({ channels: data }))
    yield put(refreshChannels(id, tab))
  } else if (error) {
    yield call(globalError, data)
    yield put(setLoading(false))
  }
}

export function* setFavoriteSaga(action) {
  const { id } = action
  const { error, data } = yield http.post(`/campaigns/channels/favorite/${id}`)
  if (error) {
    yield call(globalError, data)
  }
}

export function* unsetFavoriteSaga(action) {
  const { id } = action
  const { error, data } = yield http.delete(
    `/campaigns/channels/favorite/${id}`
  )
  if (error) {
    yield call(globalError, data)
  }
}
